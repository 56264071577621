<template>
  <div class="mt-1 mx-3">
    <agency-books> </agency-books>
  </div>
</template>

<script>
import AgencyBooks from "@/components/accounting/accounting-agency/agency-books/index.vue";
import { mapActions } from "vuex";

export default {
  components: {
    AgencyBooks,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
};
</script>
