<template>
  <div class="mt-1 mx-3">
    <agency-books-create> </agency-books-create>
  </div>
</template>

<script>
import AgencyBooksCreate from "@/components/accounting/accounting-agency/agency-books/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    AgencyBooksCreate,
  },
  created() {
    this.notifyQueue({
      text: "Crea Movimento",
      path: "agency_books.create1",
      level: 4,
    });
  },
};
</script>
