<template>
  <b-modal
    id="quickSearchAgencyContacts"
    size="lg"
    scrollable
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="people"></b-icon>
        <span>Cerca Anagrafiche Contatti</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>

    <b-form @submit.prevent="onSearch(filterName)">
      <b-row>
        <div class="col-md-3">
          <base-input
            name="last_name"
            v-model="filter.byAttribute.SURN"
            type="text"
            label="Cognome"
            placeholder="Inserisci un cognome"
          />
        </div>
        <div class="col-md-3">
          <base-input
            name="name"
            v-model="filter.byAttribute.NAME"
            type="text"
            label="Nome"
            placeholder="Inserisci un nome"
          />
        </div>
        <div class="col-md-3">
          <base-input
            name="NINO"
            v-model="filter.byAttribute.NINO"
            type="text"
            label="Codice Fiscale"
            placeholder="Inserisci un codice fiscale"
          />
        </div>
        <div class="col-md-3">
          <base-input
            name="CNAM"
            v-model="filter.byAttribute.CNAM"
            type="text"
            label="Ragione Sociale"
            placeholder="Inserisci una ragione sociale"
          />
        </div>
        <div class="col-md-3">
          <base-input
            name="CREG"
            v-model="filter.byAttribute.CREG"
            type="text"
            label="PIVA"
            placeholder="Inserisci una partita IVA"
          />
        </div>
        <div class="col-md-3">
          <base-select
            name="CLI"
            label="Cliente"
            v-model="filter.byAttribute.CLI"
            :options="opt_radio"
          />
        </div>
        <div class="col-md-3">
          <base-select
            name="SUP"
            label="Fornitore"
            v-model="filter.byAttribute.SUP"
            :options="opt_radio"
          />
        </div>
      </b-row>
      <b-row>
        <div class="form-group col-md-6 align-self-end">
          <b-button type="submit" variant="lisaweb" size="sm">Cerca</b-button>
          <b-button
            class="btn-reset"
            type="button"
            variant="lisaweb"
            size="sm"
            @click="onClearFilter(filterName)"
            >Reset</b-button
          >
        </div>
      </b-row>
    </b-form>
    <agency-contacts-accounting
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :filterOn="{
        byRelations: ['byCorrespondence', 'byAddressBook'],
      }"
      noInnerWidth
      noActions
      includeRowSelector
      @rowSelector="onModalSelector"
      noInputPaginator
    >
    </agency-contacts-accounting>
  </b-modal>
</template>
<script>
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
// import AgencyContactsAccounting from "@/components/tables/AgencyContactsAccounting";
import { mapActions } from "vuex";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterQuickSearchAgencyContact",
      repository: "agency_contact",
      resource: "agency_contacts",
      tableRef: "QuickSearchAgencyContactTableRef",
      opt_radio: [
        { text: "Si", value: "Y" },
        { text: "No", value: "N" },
      ],
      fields: [
        {
          key: "rowSelector",
          label: "Azioni",
          thStyle: {
            width: "5px",
          },
        },
        {
          key: "attributables.CNAM",
          label: "Ragione Sociale",
          sortable: true,
          sortKey: "attributables.CNAM",
        },
        {
          key: "attributables.CREG",
          label: "Partita Iva",
          sortable: true,
          sortKey: "attributables.CREG",
        },
        {
          key: "attributables.NAME",
          label: "Nome",
          sortable: true,
          sortKey: "attributables.NAME",
        },
        {
          key: "attributables.SURN",
          label: "Cognome",
          sortable: true,
          sortKey: "attributables.SURN",
        },
        {
          key: "attributables.NINO",
          label: "Codice Fiscale",
          sortable: true,
          sortKey: "attributables.NINO",
        },
        {
          key: "attributables.CLI",
          label: "Cliente",
          sortable: true,
          sortKey: "attributables.CLI",
        },
        {
          key: "attributables.SUP",
          label: "Fornitore",
          sortable: true,
          sortKey: "attributables.SUP",
        },
      ],
    };
  },
  components: {
    AgencyContactsAccounting: () =>
      import("@/components/tables/AgencyContactsAccounting"),
    // AgencyContactsAccounting,
    BaseInput,
    BaseSelect,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          status_agencycontact: 1,
          NAME: null,
          SURN: null,
          CNAM: null,
          NINO: null,
          CREG: null,
          CLI: [],
          SUP: [],
        },
      };
      return init;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch().then(() => {
        this.removeFilterByName(name);
      });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      // this.onSearch(name);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onModalSelector(val) {
      this.$emit("input", val);
    },
  },
};
</script>
